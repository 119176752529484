.navbar-person {
  background-color: #242424;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
}

.mobile-menu-icon {
  display: none;
}

.navbar-person h2 {
  font-size: 36px;
  font-weight: 600;
  color: rgb(224, 223, 223);
  white-space: nowrap;
  letter-spacing: 5px;
}

.navbar-person .icon-login {
  /* background-color: #303030; */
  display: flex;
  align-items: center;
  gap: .5rem;
  text-decoration: none;
}

.icon-login {
  position: relative;
  display: inline-block;
}

.avatar-user {
  cursor: pointer;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #303030;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  border-radius: 10px;
}

.dropdown-menu:hover {
  border-radius: 10px;
}

.dropdown-menu a {
  padding: 12px 16px;
  text-decoration: none;
  color: white;
  display: block;
}

.icon-login .avatar-user {
  margin-right: 5px;
  color: rgb(224, 223, 223);
  cursor: pointer;
}

.icon-login .avatar-user:hover {
  cursor: pointer;
  color: tomato;
}

.linear-progress-nav {
  width: 100%;
}

.btn-subscribe {
  padding: 10px 5px;
  margin-right: 4rem;
}

.btn-subscribe a {
  text-decoration: none;
  color: white;
}

.menu-item:not(:last-child) {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .navbar-person {
    padding: 1rem;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .suar-logo {
    margin-bottom: 1rem;
  }

  .menu-right {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .menu-right.open {
    display: flex;
  }

  .menu-item {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .icon-login {
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    font-size: 24px;
    color: white;
    margin-left: auto;
  }
}