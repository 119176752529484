.container {
  padding: 46px 164px ;
  color: white;
}

.profileContainer {
  background-color: #303030;
  border-radius: 50px;
  padding: 50px 40px 20px 40px;
}

.headingProfileContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.headingProfileContainer h3 {
  margin-bottom: .5rem;
}

.editProfileForm {
  margin: 2rem auto;
  border-radius: 8px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  gap: 46px;
}

.formGroup {
  flex: 1;
  margin-right: 1em;
}

.formGroup:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

input {
  width: 100%;
  height: 60px;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  color: #ccc;
}

input::placeholder {
  color: #696767; 
}

.btnContainer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

button {
  padding: 0.7em 1.5em;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

@media (max-width: 600px) {
  .container {
    padding: 40px 30px;
    color: white;
  }
  .formRow {
    flex-direction: column;
    gap: 10px;
  }
  .formGroup {
    margin-right: 0;
    margin-bottom: 1em;
  }
  .formGroup:last-child {
    margin-bottom: 0;
  }
}