.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 5rem 0;
  position: relative;
  margin-top: 2rem;
}

.hero-container p {
  text-align: center;
  padding: 1.5rem 0 2rem 0;
}

.hero-container img {
  position: absolute;
  top: 0;
  z-index: -1;
}

.container-person {
  width: 100vw;
  background-size: cover;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-stardate {
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 28px;
  top: 25px;
}
.navbar-stardate h3 {
  font-size: 16px;
  font-weight: 100;
  color: rgb(224, 223, 223);
  margin: 0 5px;
  width: 170px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 27px;
  margin: auto 3px;
  border: none;
  background: transparent;
  color: rgb(224, 223, 223);
  outline: none;
  font-weight: bolder;
  font-size: 13.5px;
  cursor: pointer;
}

.navbar-stardate span {
  font-size: 16px;
  font-weight: 500;
  color: rgb(224, 223, 223);
  margin-left: -2px;
  margin-right: 5px;
}

.navbar-stardate h4 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(224, 223, 223);
}

.wrapper-person {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 76vw;
  margin: 5rem auto;
}

.wrapper-four {
  display: flex;
  justify-content: center;
  gap: .5rem;
  flex-wrap: wrap;
  width: 95vw;
  margin: 5rem auto;
}

.client-container {
  width: 100%;
  text-align: center;
}

.center-words-container {
  margin: 4rem 0 4rem 4rem;
  position: relative;
  color: white;
}

.heading-words {
  position: absolute;
  top: 6rem;
  left: 5rem;
}

.center-words-container h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.image-frame-feature {
  /* position: absolute;
  top: 0; */
  /* padding-bottom: 4rem; */
}

.heading-words .top-text {
  margin-bottom: 4rem;
}

.list-item {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  margin-top: 2rem;
  line-height: 1.5rem;
}

.package-container {
  padding: 4rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* icon remove di card calon member */
.icon-remove {
  width: 24px;
  height: 24px;
  background-color: tomato;
  color: white;
  position: absolute;
  bottom: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-edit {
  width: 24px;
  height: 24px;
  background-color: blue;
  color: white;
  position: absolute;
  top: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-remove:hover {
  background-color: rgb(251, 61, 61);
  color: rgb(250, 249, 249);
  cursor: pointer;
}

::placeholder {
  color: rgb(226, 226, 226);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(240, 240, 240);
}

/* INPUT DATE PICKER */
input {
  width: 100%;
  height: 27px;
  margin: auto;
  border-radius: 7px;
}

/* Loading Circle */
.loading-circle {
  width: 100vw;
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* WIDGET */
#widget {
  padding: 0 16px;
  margin: 0 auto;
  width: 15%;
  /* width: 24vw; */
}

iframe {
  border-radius: 10px !important;
  height: 375px !important;
}

/* Density Word */
.density-wrapper {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.title-density {
  white-space: wrap;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .main-container {
    overflow-x: hidden;
  }
  .navbar-person h2 {
    font-size: 29px;
  }
  .navbar-person .icon-login {
    right: 0;
  }
  .navbar-stardate h3 {
    width: 128px;
  }
  .navbar-stardate h4 {
    font-size: 8px;
  }

  .hero-container {
    padding: 3.5rem 0;
    /* margin-top: 2rem; */
  }

  .heading-words {
    position: absolute;
    top: 5rem;
    left: 5rem;
  }

  .center-words-container {
    margin: 0rem 0 0rem 0rem;
    position: relative;
    color: white;
    margin-bottom: 5rem;
  }

  .center-words-container h1 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .list-item {
    gap: 1rem;
  }

  /* package-container */
  .package-container {
    flex-direction: column;
    align-items: center;
  }
}
