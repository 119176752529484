.modal_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_form {
  width: 766px;
  background-color: #303030;
  /* border: 1px solid white; */
  padding: 12px;
  position: relative;
  border-radius: 7px;
  color: white;
}

.container_form form {
  display: flex;
  flex-direction: column;
}

.container_form span {
  display: block;
  text-align: center;
}

.icon_close_modal {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -12px;
  color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_close_modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.project_image_container {
  display: flex;
  gap: 1rem;
}

.project_image {
  width: 134px;
  height: 134px;
  background-color: #d9d9d9;
  border-radius: 10px;
  margin-bottom: .5rem;
}

.image {
  object-fit: cover;
  border-radius: 10px;
}

.input_calon,
.input_tanggal {
  height: 36px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 18px;
  background-color: transparent;
  outline: none;
  border: 1px solid white;
  color: white;
  
  }
  
  .textarea_custom {
  font-size: 18px;
  height: 100px;
  border-radius: 10px;
  background-color: transparent;
  margin-top: 5px;
  border: 1px solid white;
  color: white;
  padding: .5rem;
}

.input_gambar_calon {
  margin-top: 14px;
}

.error_message {
  color: red;
}

.btn_cari_container {
  text-align: center;
  margin-top: 1rem;
}

.btn_cari {
  height: 36px;
  width: 92px;
  background-color: rgb(2, 83, 125);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  margin-top: 1rem;
}

.btn_cari:hover {
  background-color: rgb(4, 117, 174);
  cursor: pointer;
  color: rgb(220, 220, 220);
}