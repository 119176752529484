.wrapperRegister {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 9rem;
}

.containerRegister {
  border-radius: 15px;
  width: 22rem;
  color: rgb(228, 226, 226);
}

.iconUser {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  font-size: 20px;
}

.inputGroup .inputWrapper {
  display: flex;
  align-items: center;
}

.inputWrapper input {
  border: 1px solid white;
  width: 100%;
  height: 62px;
  font-size: 16px;
  padding: 0 1rem;
  background: none;
  outline: none;
  color: white;
}

.inputWrapper input:focus {
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 9px;
  color: white;
}

.containerRegister button {
  width: 100%;
  height: 62px;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid white;
  font-size: 18px;
}
.containerRegister button:hover {
  cursor: pointer;
}

form {
  margin-bottom: 12px;
}

.containerRegister a {
  color: white;
}
.containerRegister a:hover {
  color: tomato;
}

@media screen and (max-width: 768px) {
  .wrapperRegister {
    justify-content: center;
    padding-right: 0;
  }
}