/* Button.css */
.button {
  font-size: 16px;
  padding: 10px 40px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Primary button styles */
.primary-button {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.primary-button:hover {
  background-color: lightgray;
}

/* Secondary contained button styles */
.secondary-contained-button {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.secondary-contained-button:hover {
  background-color: lightgray;
}

/* Secondary outlined button styles */
.secondary-outlined-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.secondary-outlined-button:hover {
  background-color: white;
  color: black;
}