.container_footer {
  width: 100%;
}

.divider {
  border: 1px dashed #545454;
}

.wrapper_footer {
  padding: 4rem;
}

.tradmark {
  padding-left: 8rem;
  color: rgb(224, 223, 223);
  display: flex;
  gap: 10rem;
  justify-content: flex-start;
  align-items: center;
}

.suar_logo {
  display: flex;
  flex-direction: column;
}

.tradmark span {
  font-size: 12px;
}

.center_footer {
  display: flex;
  flex-direction: column;
  line-height: 1.2rem;
}

.data_stream {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(224, 223, 223);
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .tradmark {
    flex-direction: column-reverse;
    padding-left: 0;
    align-items: flex-start;
    gap: 1rem;
  } 
  .wrapper_footer {
    padding: 1rem;
  }
}