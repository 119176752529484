.action-monitoring {
  position: fixed;
  right: 32px;
  bottom: 33px;
  display: flex;
  gap: 1rem;
}
.icon-modal {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #303030;
}

.icon-modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.button-container {
  display: flex;
}
.icon-edit {
  margin-left: 5px;
  color: white;
}

.icon-edit:hover {
  cursor: pointer;
  color: tomato;
}

.modal-container .isTanggal {
  width: 350px;
}

.input-tanggal {
  margin-bottom: 6px;
}

/* Date Picker Input*/
.date-picker {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.date-picker-input {
  width: 180;
  display: flex;
  flex-direction: row;
}

.date-picker-input span {
  flex: 1;
  padding: 0 .4rem;
  font-size: 14px;
}

.add-container {
  width: 335px;
  height: 500px;
  border-radius: 30px;
  border: 1px dashed #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-add-container {
  width: 70px;
  height: 70px;
  background-color: #b0b0b0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.icon-add-container:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}
