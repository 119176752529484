.container {
  background-color: #303030;
  color: white;
  padding: 1rem;
  border-radius: 15px;
  width: 340px;
}

.headingContainer {
  text-align: center;
}

.accountProject {
  padding: 12px 0 38px 0;
}

.benefitContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listBenefit {
  display: flex;
  gap: .5rem;
}

.bonusContainer {
  margin-bottom: 30px;
}

.bonusTitle {
  text-align: center;
  padding: 36px 0 15px 0;
}

.listContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.listBonus {
  width: 178px;
}

.buttonContainer {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {}
}