.card_person {
  width: 335px;
  height: 580px;
  text-align: center;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  /* margin-top: 35px; */
}

.header_person_container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.title_person {
  /* height: 70px; */
  width: 90px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}

.image_button {
  background-color: transparent;
  /* padding: 0; */
}

.image_button:hover {
  background: transparent;
}

.icon_remove {
  width: 24px;
  height: 24px;
  background-color: tomato;
  color: white;
  position: absolute;
  bottom: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_edit {
  width: 24px;
  height: 24px;
  background-color: blue;
  color: white;
  position: absolute;
  top: -1vh;
  right: -1vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon_remove:hover {
  background-color: rgb(251, 61, 61);
  color: rgb(250, 249, 249);
  cursor: pointer;
}

.title_person img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* border-radius: 8px; */
  /* border: 1px solid #303030; */
}

.wrapper_name {
  text-transform: uppercase;
  color: white;
}

.wrapper_name span {
  color: rgb(218, 216, 216);
  font-size: 17px;
  font-weight: 600;
}

.contents_person {
  height: 68%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  background-color: #303030;
  border-radius: 30px;
  padding: .7rem;
  padding-top: 1rem;
  /* mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */
}

.contents_person:hover {
  mask-image: none;
  -webkit-mask-image: none;
}

.social_media {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.icon_sosial {
  flex: 1;
}

.social_media .icon_sosial:hover,
.active {
  cursor: pointer;
  color: tomato;
}

.numfound_wrapper {
  height: 7%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: white;
}

.numfound_wrapper .numfound {
  flex: 1;
  font-weight: 600;
  font-size: 20px;
  font-family: "IBM Plex Sans";
}

.content_article {
  background-color: #444444;
  /* height: 0%; */
  width: 99%;
  overflow-y: auto;
  border-radius: 25px;
  padding: .7rem 1rem;
  margin-top: 1rem;
}

.content_article .article {
  color: white;
  border-bottom: 1px dashed #B5B3B3;
  padding: 5px 0;
  font-family: "Roboto";
  font-weight: 300;
  margin-bottom: 7px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s ease;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content_article .article:hover {
  cursor: pointer;
  color: tomato;
}

a {
  text-decoration: none;
  color: rgb(21, 31, 165);
}
a:hover {
  color: rgb(230, 230, 230);
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(243, 243, 243, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 35, 53);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 61, 92);
}


::placeholder {
  color: rgb(226, 226, 226);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(240, 240, 240);
}

/* INPUT DATE PICKER */
input {
  width: 100%;
  height: 27px;
  margin: auto;
  border-radius: 7px;
}