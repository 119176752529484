.wrapper_login {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.container_login {
  /* border: 1px solid white; */
  /* background-color: rgba(240, 240, 240, 0.2); */
  border-radius: 15px;
  width: 22rem;
  padding: 14px;
  color: rgb(228, 226, 226);
}

.suar_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  cursor: pointer;
}

.input_group {
  margin-bottom: 10px;
}

.input_group label {
  font-size: 20px;
}

.input_group input {
  border: 1px solid white;
  height: 62px;
  width: 100%;
  padding: 0 1rem;
  font-size: 16px;
  margin-right: 5px;
  background-color: rgb(202, 202, 202);
  background: none;
  outline: none;
  color: white;
}

.container_login button {
  width: 100%;
  height: 62px;
  border-radius: 5px;
  margin-top: 20px;
  color: black;
  background-color: white;
  border: 1px solid white;
  font-size: 18px;
}
.container_login button:hover {
  cursor: pointer;
  /* background-color: rgb(1, 56, 83); */
}

form {
  margin-bottom: 12px;
}

.container_login a {
  color: white;
}
.container_login a:hover {
  color: tomato;
}

@media screen and (max-width: 768px) {
  .bg_image {
    display: none;
  }
}