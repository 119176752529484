.content-container {
  margin-bottom: 1rem;
}
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-form {
  width: 766px;
  background-color: #303030;
  /* border: 1px solid white; */
  padding: 1.5rem;
  position: relative;
  border-radius: 7px;
  color: white;
}

.container-form form {
  display: flex;
  flex-direction: column;
}

.container-form span {
  display: block;
  text-align: center;
}

.icon-close-modal {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -12px;
  color: tomato;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-close-modal:hover {
  cursor: pointer;
  color: white;
  background-color: tomato;
}

.project-image-container {
  display: flex;
  gap: 1rem;
}

.project-image {
  width: 134px;
  height: 134px;
  background-color: #d9d9d9;
  border-radius: 10px;
  margin-bottom: .5rem;
}

.image {
  object-fit: cover;
  border-radius: 10px;
}

.input-calon,
.input-tanggal {
  height: 36px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 18px;
  background-color: transparent;
  outline: none;
  border: 1px solid white;
  color: white;
  
  }
  
  .textarea-custom {
  font-size: 18px;
  height: 100px;
  border-radius: 10px;
  background-color: transparent;
  margin-top: 5px;
  border: 1px solid white;
  color: white;
  padding: .5rem;
}

.input-gambar-calon {
  margin-top: 14px;
}

.btn-cari-container {
  text-align: center;
  margin-top: 1rem;
}

.btn-cari {
  height: 36px;
  background-color: rgb(2, 83, 125);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  margin-top: 1rem;
}

.btn-cari:hover {
  background-color: rgb(4, 117, 174);
  cursor: pointer;
  color: rgb(220, 220, 220);
}

@media only screen and (max-width: 768px) {
  .container-form {
    width: 90vw;
  }
}